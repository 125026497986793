import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  flightingAppEnvironment: 'Prod',
  esxpBaseUrl: 'https://esxp.microsoft.com/',
  cdnBaseUrl: 'https://gcmra-ui-prod-react-ghd0bfbjabb9gndf.b02.azurefd.net/availability',
  serviceEndPoints: [
    {
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
      serviceEndPoint: 'https://oneprofileprodapipack.azurefd.net',
    },
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://professionalservices.microsoft.com/lmt-coreapi',
    },
    {
      clientId: 'bc72c2f2-9689-42a0-aa49-f579509c867b',
      serviceEndPoint: 'https://esxp.microsoft.com/rmavl',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp-prod.azurefd.net/grm/rm',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp.microsoft.com/api/v1/Search',
    },
    {
      clientId: 'bc72c2f2-9689-42a0-aa49-f579509c867b',
      serviceEndPoint: 'https://emailnotifications-prod-wus.azurewebsites.net',
    },
    {
      clientId: 'bc72c2f2-9689-42a0-aa49-f579509c867b',
      serviceEndPoint: 'https://maintenancebackgroundfunction-prod-wus.azurewebsites.net',
    },
    {
      clientId: 'e03cd7c9-8c94-45dd-b4b8-68957267ec20',
      serviceEndPoint: 'https://pslakeds.azurewebsites.net',
    }
  ],
  OneProfileBaseUrl: 'https://oneprofileprodapipack.azurefd.net',
  TimeCoreBaseUrl: 'https://professionalservices.microsoft.com/lmt-coreapi',
  EmailNotificationBaseUrl:
    'https://emailnotifications-prod-wus.azurewebsites.net',
  TimeSubscriptionKey: 'acc2cbb3f24e4f7ba55220041d0ce2fd',
  AvailabilityBaseUrl: 'https://esxp.microsoft.com/rmavl',
    UpdateAutomatedForecastUrl: 'https://maintenancebackgroundfunction-prod-wus.azurewebsites.net',
    GrmApiBaseUrl: 'https://esxp-prod.azurefd.net/grm/rm',
    GrmAzureSearchUrl: 'https://esxp.microsoft.com/api/v1/Search',
    PsdlISDForecastBaseUrl :'https://pslakeds.azurewebsites.net',
  ProjectDetailsEndUrl:
    '/grmprod/grm-assignmentsearch-prod-index',
  TimeAssetsUrl: 'https://LMT-AFD-ENDPOINT-PROD-a5hrgyg7a2edh9h9.b02.azurefd.net/assets/',
  RMResourceForecast: {
    NonProjectEventPriority: [63, 35, 22, 16, 13, 33, 21, 37, 11, 4, 17],
    ClarityCode: '',
  },
  RMExperience: {
    batchSize: 5,
    numAvAPIParallelCalls: 2,
    RMPageSize: 10,
    isCosmosEndpoint: true,
  },
};
